export class Questao{

  constructor(id: string, Descricao: string, Numero: string, QuestionarioID: string, Tipo: string)
   {
      this.id = id;
      this.Descricao = Descricao;
      this.Numero = Numero;
      this.QuestionarioID = QuestionarioID;
      this.Tipo= Tipo;
    }
  public id: string
  public Descricao: string
  public Numero: string
  public QuestionarioID: string
  public Tipo: string
}
