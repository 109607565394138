import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Item} from '../shared/relatorio.item';
import {ITEMS} from '../shared/relatorios.ITEMS';
import {ItemService} from '../service'
import {CabDet} from '../shared/PadraoRelCabDet.model';

@Component({
  selector: 'app-relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.css'],
  providers: [ItemService]
})
export class RelatorioComponent implements OnInit {

    radioSel:any;
    radioSelected:string;
    radioSelectedString:string;
    itemsList: Item[] = ITEMS;
    public formRel: FormGroup
    public ret: string
    public loading: boolean
    public cabdet: CabDet
    public cab: object
    public det:object

  constructor(private fb: FormBuilder,
              private itemService: ItemService) {
        this.itemsList = ITEMS
        console.log(this.itemsList)
        this.radioSelected = "item_3";
        this.getSelecteditem();
  }

  getSelecteditem(){
       this.radioSel = ITEMS.find(Item => Item.value === this.radioSelected);
       this.radioSelectedString = JSON.stringify(this.radioSel);
     }

     onItemChange(item){
       this.getSelecteditem();
     }

  ngOnInit() {
    this.formRel = this.fb.group({
      Cidade: this.fb.control('', [Validators.required, Validators.minLength(4)]),
      Data: this.fb.control('', [Validators.required, Validators.minLength(6)]),
      Tipo: this.fb.control('', [Validators.required, Validators.minLength(6)]),
    })
  }

  Acessar(): void {
      console.log(this.formRel.value.Cidade,
      this.formRel.value.Data,this.formRel.value.Tipo )

      this.itemService.RetornaCidades(this.formRel.value.Cidade,
      this.formRel.value.Data,this.formRel.value.Tipo)
                              .subscribe((ret: CabDet) => {
                                this.cabdet=ret
                                this.cab=this.cabdet.Cabecalho
                                this.det=this.cabdet.Detalhe
                                console.log('retorno do cache',ret)
                                },
                                ((erro:any) => console.log('erro postPessoa:', erro.status)),
                                () => console.log('Login Completo'),
                                )
                                this.loading = false;
  }


}
