export class Respo{

  constructor(id: string, Descricao: string, Numero: string, Correta: string, QuestaoId: string, Tipo: string, Sel: boolean)
   {
      this.id = id;
      this.Descricao = Descricao;
      this.Numero = Numero;
      this.QuestaoId = QuestaoId;
      this.Correta= Correta;
      this.Tipo= Tipo;
      this.Sel=Sel;
    }
  public id: string
  public Descricao: string
  public Numero: string
  public QuestaoId: string
  public Correta: string
  public Tipo: string
  public Sel: boolean
}
