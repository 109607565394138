import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


@Injectable()

export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor() { }
 intercept(
  req: HttpRequest<any>,
  next: HttpHandler,
 ): Observable<HttpEvent<any>> {
   //console.log(this.router.url+'---'+this.route.snapshot.params['id'])
  const dupReq = req.clone({
   headers: req.headers.set('acessToken', (localStorage.getItem('acessToken')==null ? '' : localStorage.getItem('acessToken'))),
 });
 return next.handle(dupReq);
 }
}


@NgModule({
providers: [
 {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpsRequestInterceptor,
  multi: true,
 },
],
})


export class Interceptor {}
