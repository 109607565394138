import {EventEmitter} from '@angular/core'

export class MensagemService {
  notifier = new EventEmitter<string>()

    notify(message: string){

    this.notifier.emit(message)
  }

  async delay(ms: number, mesg: string) {
     await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
  }


  Mensagens(mens: any, tip: number) {
    let men=mens as any
    let m='' as string
    let tempo=2000 as number
    let element = document.getElementById("txtMensagem") as any;
    element.value=men
    if (tip===2) {
      console.log('Mensagens erro:',mens)
      tempo=5000
      men=mens //.error
      //let c=mens.length as number
      let j=0 as number
      let k=1 as number
      /*for (var i = 0; i<c; i++){
          var e=mens[i].error
          //if (e.startsWith('ERRO #5002: Erro Caché: <ROLLFAIL>')){
          //}else{
            if (m!='') m=m+'%5Cr%5Cn' //quebra de linha no textarea CrLf
            m=m+'Erro-'+k+': '+e
            k++
          //}
      }*/
      m=mens
      }

console.log('erro mensagem:'+m)
    let elementt = document.getElementById("txtMensagemta") as any;
    elementt.value=m
    let element0 = document.getElementById("txtTipo") as any;
    element0.value=tip;
    let element1 = document.getElementById("btnMensagem") as any;
    element1.click();

    this.delay(tempo,men).then(any=>{element1.click()})


  }

}
