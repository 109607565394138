export class Pessoa{
  constructor(id: string, Nome: string,Nascimento: Date, Email: string, Cpf: string,Telefone: string,EstadoCivil: string,Genero: string,
CidadeNascimento: string,EnderecoCep: string,EnderecoEndereco: string,EnderecoNumero: string, EnderecoComplemento: string,
EnderecoBairro: string,EnderecoCIDADE: string,AreaAtuacao: string,CargoInteresse: string,NivelHierarquico: string,
PretensaoSalarial: string,Empresa: string,Cargo: string,Entrada: Date, Saida: Date,Salario: number,PrincipaisAtividades: string,
Idioma: Array<object>,FormacaoTipo:Array<object>,Formacao: Array<object>,CidadeNascimentoid: string,Senha: string,Cidades: Array<object>,
EnderecoCIDADECIDADE: string,EnderecoCIDADEUF: string) {
      this.id = id;
      this.Nome = Nome;
      this.CidadeNascimento = CidadeNascimento;
      this.CidadeNascimentoid = CidadeNascimentoid;
      this.Email = Email;
      this.Telefone = Telefone;
      this.Cpf = Cpf;
      this.Nascimento = Nascimento;
      this.Senha = Senha;
      this.Genero = Genero;
      this.EstadoCivil = EstadoCivil
      this.CargoInteresse = CargoInteresse
      this.AreaAtuacao = AreaAtuacao
      this.NivelHierarquico = NivelHierarquico
      this.PretensaoSalarial = PretensaoSalarial
      this.Cargo = Cargo
      this.Empresa = Empresa
      this.Entrada = Entrada
      this.Saida = Saida
      this.PrincipaisAtividades = PrincipaisAtividades
      this.Salario = Salario
      this.Idioma = Idioma
      this.Cidades=Cidades
      this.EnderecoEndereco = EnderecoEndereco
      this.EnderecoNumero = EnderecoNumero
      this.EnderecoComplemento = EnderecoComplemento
      this.EnderecoBairro = EnderecoBairro
      this.EnderecoCep = EnderecoCep
      this.EnderecoCIDADE = EnderecoCIDADE
      this.EnderecoCIDADECIDADE = EnderecoCIDADECIDADE
      this.EnderecoCIDADEUF = EnderecoCIDADEUF
      this.Formacao=Formacao
      this.FormacaoTipo=FormacaoTipo
    }

  public id: string
  public Nome: string
  public CidadeNascimento: string
  public CidadeNascimentoid: string
  public Email: string
  public Telefone: string
  public Cpf: string
  public Nascimento: Date
  public Senha: string
  public Genero: string
  public EstadoCivil: string
  public CargoInteresse: string
  public AreaAtuacao: string
  public NivelHierarquico: string
  public PretensaoSalarial: string
  public Cargo: string
  public Empresa: string
  public Entrada: Date
  public Saida: Date
  public PrincipaisAtividades: string
  public Salario: number
  public Idioma: Array<object>
  public Cidades: Array<object>
  public EnderecoEndereco: string
  public EnderecoNumero: string
  public EnderecoComplemento: string
  public EnderecoBairro: string
  public EnderecoCep: string
  public EnderecoCIDADE: string
  public EnderecoCIDADECIDADE: string
  public EnderecoCIDADEUF: string
  public Formacao:Array<object>
  public FormacaoTipo: Array<object>
}
