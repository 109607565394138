import {Routes, RouterModule} from  '@angular/router'
import {ModuleWithProviders} from '@angular/core'
import {HomeComponent} from './home/home.component'

import {Tela1Component} from './tela1/tela1.component'

import {Tela21Component} from './tela21/tela21.component'
//import { TelaEdicaoComponent } from './tela-edicao/tela-edicao.component';
import {AuthGuard} from './auth/guard.module'
import {RelatorioComponent} from './relatorio/relatorio.component'
import {CurriculumComponent} from './curriculum/curriculum.component'
import { ConsultaComponent } from './consulta/consulta.component'
import { VagasComponent } from './vagas/vagas.component'
import {QuestionarioComponent} from './questionario/questionario.component'
import {NovoquestionarioComponent} from "./novoquestionario/novoquestionario.component"
import { ProvaComponent } from './prova/prova.component';
import {SelecaoComponent} from './selecao/selecao.component'

export const ROUTES: Routes = [
    {path: '', component: HomeComponent},
    {path: 'tela1', component: Tela1Component },
    {path: 'tela1/:id', component: Tela1Component },
    {path: 'tela21', component: Tela21Component, canActivate: [AuthGuard]},
    //{path: 'edicao/:id', component: TelaEdicaoComponent, canActivate: [AuthGuard]},
    {path: 'tela3', component: RelatorioComponent},
    {path: 'Curriculum', component: CurriculumComponent},
    {path: 'Curriculum/:id', component: CurriculumComponent},
    {path: 'Curriculum/Vaga/:cod', component: CurriculumComponent},
    {path: 'Consulta', component: ConsultaComponent },
    {path: 'questionario', component: QuestionarioComponent, canActivate: [AuthGuard]  },
    {path: 'Vagas', component: VagasComponent },
    {path: 'novoquestionario/:id', component: NovoquestionarioComponent },
    {path: 'novoquestionario', component: NovoquestionarioComponent },
    {path: 'prova', component: ProvaComponent },
    {path: 'prova/:id', component: ProvaComponent },
    {path: 'selecao', component: SelecaoComponent },
    {path: ':id', component: HomeComponent},
    { path: '**', redirectTo: 'home' }
  ]

  export const routing: ModuleWithProviders = RouterModule.forRoot(ROUTES);
