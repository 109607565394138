import {Http, Response, Headers, RequestOptions } from '@angular/http'
import {HttpClient} from '@angular/common/http'
import {Observable, pipe} from 'rxjs'
import { map, retry, tap } from 'rxjs/operators'
import 'rxjs/operators' //import 'rxjs/add/operator/toPromise' para a versao 6 usar a notacao abaixo
import {Injectable} from '@angular/core'

import {URL_API} from './api'

import {Item} from './shared/Padroes.model'
import {TaPa} from './shared/PadroesTP.model'
import { Quest } from './shared/PadraoQuest.model'
import {Pessoa} from './shared/PadroesEdicaoPessoa.model'
import {Cidade} from './shared/PadraoCidade.model'
import {LoginMod} from './shared/PadraoLogin.model'
import {CabDet} from './shared/PadraoRelCabDet.model';
import { Questao } from './shared/PadraoQuestao.model';
import { Respo } from './shared/PadraoResposta.model';
import {Selecao} from './shared/PadraoSelecao.model'
import { Selecoes } from './shared/PadraSelecoes.model';
import { Pess } from './shared/PadraoPessoas.model';

@Injectable()
export class ItemService {

  public user: LoginMod


  constructor(private http: HttpClient){}
  //constructor(){}

  /*public itens: Item[] = [
    {It: 'Item-111', Sit: false, Sub: [
			]},
    {It: 'Item-222 com DropSown', Sit: false, Sub: [
				{"url": "/assets/ofertas/1/img123.jpg"},
				{"url": "/assets/ofertas/1/img2.jpg"},
				{"url": "/assets/ofertas/1/img3.jpg"},
				{"url": "/assets/ofertas/1/img4.jpg"}
			]},
    {It: 'Item-333', Sit: true, Sub: [
				{"url": "/assets/ofertas/1/img1.jpg"},
				{"url": "/assets/ofertas/1/img2.jpg"},
				{"url": "/assets/ofertas/1/img3.jpg"},
				{"url": "/assets/ofertas/1/img4.jpg"}
			]},
    {It: 'Item-444', Sit: false, Sub: [
				{"url": "/assets/ofertas/1/img1.jpg"},
				{"url": "/assets/ofertas/1/img2.jpg"},
				{"url": "/assets/ofertas/1/img3.jpg"},
				{"url": "/assets/ofertas/1/img4.jpg"}
			]},
    {It: 'Item-555', Sit: false, Sub: []}
  ]*/

  public getCabecalhoPorId(id: string): Promise<Item[]>{
    return this.http.get(`${URL_API}/cabecalho/${id}`)
    .toPromise()
    .then((resposta: any) => {
       //console.log("Teste: " + resposta)
      return resposta //.shift()
    })
  }

  public getCarregarTPporEmp(Emp: number): Promise<TaPa[]>{
    return this.http.get(`${URL_API}/getconsulta`)
    .toPromise()
    .then((resposta: any) => {
       //console.log("Tablepane: " + resposta)
      return resposta //.shift()
    })
  }
  //Questionario
  public getCarregarQuestPorSit(sit: number): Promise<Quest[]>{
    return this.http.get(`${URL_API}/getquestionariossit/${sit}`)
    .toPromise()
    .then((resposta: any) => {
       //console.log("Tablepane: " + resposta)
      return resposta //.shift()
    })
  }
  public getQuestionarioPorID(qid: string): Promise<Quest[]>{
    return this.http.get(`${URL_API}/getquestionarioid/${qid}`)
    .toPromise()
    .then((resposta: any) => {
       //console.log("Tablepane: " + resposta)
      return resposta //.shift()
    })
  }

  public getPessoaPorId(id: string): Promise<Pessoa[]>{
    return this.http.get(`${URL_API}/getcurriculum/${id}`)
    .toPromise()
    .then((resposta: any) => {
       //console.log("Edicao: " + resposta)
      return resposta //.shift()
    })
  }

  public getCidadePorCep(Cep: string): Promise<Cidade[]>{
    return this.http.get(`${URL_API}/retornacep/${Cep}`)
    .toPromise()
    .then((resposta: any) => {
       console.log("Edicao: " + resposta.Endereco)
      return resposta //.shift()//.pipe(retry(10))
    })
  }
  postPessoa(pessoa: Pessoa): Observable<any>{
    /*let httpOptions = {
      headers: new Headers({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'}
      )};*/
      //alert('postPessoa')
      let headers: Headers = new Headers()
      headers.append('Content-Type',  'application/json')
      headers.append('Access-Control-Allow-Origin',  '*')
      headers.append('Access-Control-Allow-Headers', 'Content-Type')
      //console.log("salvar: " + JSON.stringify(pessoa))
      return this.http.post(`${URL_API}/salvarcur`,JSON.stringify(pessoa))

  }
  postQuest(que: Quest): Observable<any>{
    /*let httpOptions = {
      headers: new Headers({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'}
      )};*/
      //alert('postPessoa')
      let headers: Headers = new Headers()
      headers.append('Content-Type',  'application/json')
      headers.append('Access-Control-Allow-Origin',  '*')
      headers.append('Access-Control-Allow-Headers', 'Content-Type')
      //console.log("salvar: " + JSON.stringify(pessoa))
      return this.http.post(`${URL_API}/salvarquestionario`,JSON.stringify(que))

  }

  salvarQuestao(que: Questao): Observable<any>{
    /*let httpOptions = {
      headers: new Headers({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'}
      )};*/
      //alert('postPessoa')
      let headers: Headers = new Headers()
      headers.append('Content-Type',  'application/json')
      headers.append('Access-Control-Allow-Origin',  '*')
      headers.append('Access-Control-Allow-Headers', 'Content-Type')
      //console.log("salvar: " + JSON.stringify(pessoa))
      return this.http.post(`${URL_API}/salvarquestao`,JSON.stringify(que))

  }
  public pesquisaCidades(termo: string): Observable<Cidade[]>{
    //console.log('pesquisaCidades:'+termo)
    return this.http.get<Cidade[]>(`${URL_API}/cidades/${termo}`)
    .pipe(retry(10))
      //map recupera a resposta no formato response e depois transforma em json e retorna
  }


  public RetornaCidades(cidade: string, data: Date, tipo: string): Observable<CabDet>{
    return this.http.get<CabDet>(`${URL_API}/retornacidades/${cidade}/${data}/${tipo}`)
    .pipe(retry(10))
      //map recupera a resposta no formato response e depois transforma em json e retorna
  }

      //map recupera a resposta no formato response e depois transforma em json e retorna


public getRespostas(qid: string): Promise<Respo[]>{
  return this.http.get(`${URL_API}/getrespostas/${qid}`)
  .toPromise()
  .then((resposta: any) => {
     //console.log("Tablepane: " + resposta)
    return resposta //.shift()
  })
}

public getResposta(qid: string, respid: string): Promise<Respo[]>{
  return this.http.get(`${URL_API}/getrespostas/${qid}/${respid}`)
  .toPromise()
  .then((resposta: any) => {
     //console.log("Tablepane: " + resposta)
    return resposta //.shift()
  })
}
salvarResposta(que: Respo): Observable<any>{
  /*let httpOptions = {
    headers: new Headers({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'}
    )};*/
    //alert('postPessoa')
    let headers: Headers = new Headers()
    headers.append('Content-Type',  'application/json')
    headers.append('Access-Control-Allow-Origin',  '*')
    headers.append('Access-Control-Allow-Headers', 'Content-Type')
    return this.http.post(`${URL_API}/salvarresposta`,JSON.stringify(que))
}

salvarProva(res: object): Observable<any>{
  /*let httpOptions = {
    headers: new Headers({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'}
    )};*/
    //alert('salvarProva'+JSON.stringify(res))
    let headers: Headers = new Headers()
    headers.append('Content-Type',  'application/json')
    headers.append('Access-Control-Allow-Origin',  '*')
    headers.append('Access-Control-Allow-Headers', 'Content-Type')
    return this.http.post(`${URL_API}/salvarprova`,JSON.stringify(res))
}

public getSelecaoPessoa(pessoaid: string): Promise<Selecao[]>{
  alert('getSelecaoPessoa')
  let headers: Headers = new Headers()
  /*headers.append('Content-Type',  'application/json')
  headers.append('Access-Control-Allow-Origin',  '*')
  headers.append('Access-Control-Allow-Credentials', 'true')
  //headers.append('Access-Control-Allow-Headers', 'Content-Type')
  */
  headers.append('Access-Control-Allow-Origin',  'http://qualquer.com.br')
  //headers.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, PATCH, OPTIONS')
  //headers.append('Access-Control-Allow-Headers', 'X-Requested-With, Content-Type, Accept, Origin, Authorization')
  //headers.append('Access-Control-Max-Age', '86400')

  return this.http.get(`${URL_API}/getselecao/${pessoaid}`)
  .toPromise()
  .then((resposta: any) => {
     //console.log("Tablepane: " + resposta)
    return resposta //.shift()
  })
}
  public getSelecoes(sit:string, sid: string): Promise<Selecoes[]>{
    let headers: Headers = new Headers()
    headers.append('Access-Control-Allow-Origin',  '*')
    return this.http.get(`${URL_API}/getselecoes/${sit}/${sid}`)
    .toPromise()
    .then((resposta: any) => {
       //console.log("Tablepane: " + resposta)
      return resposta //.shift()
    })
  }

  salvarSelecao(res: object): Observable<any>{
    /*let httpOptions = {
      headers: new Headers({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'}
      )};*/
      //alert('salvarProva'+JSON.stringify(res))
      let headers: Headers = new Headers()
      headers.append('Content-Type',  'application/json')
      headers.append('Access-Control-Allow-Origin',  '*')
      headers.append('Access-Control-Allow-Headers', 'Content-Type')
      return this.http.post(`${URL_API}/salvarselecao`,JSON.stringify(res))
  }

  public getCandidatos(sid: string): Promise<Pess[]>{
    let headers: Headers = new Headers()
    headers.append('Access-Control-Allow-Origin',  '*')
    return this.http.get(`${URL_API}/getcandidatos/${sid}`)
    .toPromise()
    .then((resposta: any) => {
       //console.log("Tablepane: " + resposta)
      return resposta //.shift()
    })
  }

  acrescentarPessoa(sid: string, pid:string, tip:string, pessid: string): Observable<any>{
      let headers: Headers = new Headers()
      headers.append('Content-Type',  'application/json')
      headers.append('Access-Control-Allow-Origin',  '*')
      headers.append('Access-Control-Allow-Headers', 'Content-Type')
      return this.http.post(`${URL_API}/salvarselecaopessoa`,JSON.stringify({ sid: sid, pid: pid, Tipo: tip, pessId: pessid }))
  }

  EnviarEmail(sid: string, des:string): Observable<any>{
      let headers: Headers = new Headers()
      headers.append('Content-Type',  'application/json')
      headers.append('Access-Control-Allow-Origin',  '*')
      headers.append('Access-Control-Allow-Headers', 'Content-Type')
      return this.http.post(`${URL_API}/enviaremail`,JSON.stringify({ sid: sid, descricao: des}))
  }
}
