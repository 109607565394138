import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpModule} from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import { FormsModule,ReactiveFormsModule  } from "@angular/forms";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';

import { AppComponent } from './app.component';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { RodapeComponent } from './rodape/rodape.component';
import { HomeComponent } from './home/home.component';
import { Tela1Component } from './tela1/tela1.component';
import { Tela21Component } from './tela21/tela21.component';

import {ROUTES} from './app.routes';
import {routing} from './app.routes';

//import { TelaEdicaoComponent } from './tela-edicao/tela-edicao.component';
import { MensagemComponent } from './mensagem/mensagem.component';
import { LoginComponent } from './login/login.component';
import {LoginService} from './login/login.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {Interceptor} from './auth/interceptor.module';
library.add(fas);
import {AuthGuard} from './auth/guard.module';
import { RelatorioComponent } from './relatorio/relatorio.component';
import { CurriculumComponent } from './curriculum/curriculum.component';
import { ConsultaComponent } from './consulta/consulta.component';
import { VagasComponent } from './vagas/vagas.component';
import { QuestionarioComponent } from './questionario/questionario.component';
import { NovoquestionarioComponent } from './novoquestionario/novoquestionario.component';
import { ProvaComponent } from './prova/prova.component';
import { SelecaoComponent } from './selecao/selecao.component';

@NgModule({
  declarations: [
    AppComponent,
    CabecalhoComponent,
    RodapeComponent,
    HomeComponent,
    Tela1Component,
    Tela21Component,
    //TelaEdicaoComponent,
    MensagemComponent,
    LoginComponent,
    RelatorioComponent,
    CurriculumComponent,
    ConsultaComponent,
    VagasComponent,
    QuestionarioComponent,
    NovoquestionarioComponent,
    ProvaComponent,
    SelecaoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES),
    ReactiveFormsModule,
    FontAwesomeModule,
    Interceptor,
    NgxMaskModule.forRoot(),
    FormsModule,
    routing
  ],
  providers: [LoginService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
