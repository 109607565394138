export class Quest{

  constructor(id: string, Descricao: string, Empresa: string, Codigo: string, Data: string, Situacao: boolean, Cabecalho: Array<object>,
    Questoes: Array<object>,Respostas: Array<object>)
   {
      this.id = id;
      this.Descricao = Descricao;
      this.Empresa = Empresa;
      this.Codigo = Codigo;
      this.Data = Data;
      this.Situacao = Situacao;
      this.Cabecalho = Cabecalho;
      this.Questoes = Questoes;
      this.Respostas = Respostas;
    }
  public id: string
  public Descricao: string
  public Empresa: string
  public Codigo: string
  public Data: string
  public Situacao: boolean
  public Cabecalho: Array<object>
  public Questoes: Array<object>
  public Respostas: Array<object>}
