import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-tela1',
  templateUrl: './tela1.component.html',
  styleUrls: ['./tela1.component.css']
})
export class Tela1Component implements OnInit {
  public SelecaoPessoaId: string;

    constructor(private route: ActivatedRoute, private router: Router) { }

    ngOnInit() {
      alert('tela1')
      this.route.queryParams.subscribe(params => {
            this.SelecaoPessoaId = params['id'];
            alert(this.SelecaoPessoaId)
    })
  }

    AtivarProva(){
      alert(this.SelecaoPessoaId)
    this.router.navigate(['prova',this.SelecaoPessoaId])
      //alert(this.SelecaoPessoaId)
    }

  }
