import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, catchError } from 'rxjs/operators';

import { Pessoa } from '../shared/PadroesEdicaoPessoa.model';
import { ItemService } from '../service';
import {LoginService} from '../login/login.service'
import { MensagemService } from '../mensagem/mensagem.service';
import { Cidade } from '../shared/PadraoCidade.model';
import { Questao } from '../shared/PadraoQuestao.model';
import { Quest } from '../shared/PadraoQuest.model';
import { Respo } from '../shared/PadraoResposta.model';
import * as $ from 'jquery';


@Component({
  selector: 'app-novoquestionario',
  templateUrl: './novoquestionario.component.html',
  styleUrls: ['./novoquestionario.component.css'],
  providers: [ItemService, MensagemService]
})

@Injectable()
export class NovoquestionarioComponent implements OnInit {
  public formPessoa: FormGroup;
  public tIdioma: any;
  public Idioma: Array<any>;
  public QuestionarioID: any;
  public QuestaoID: any;
  public pessoa: Pessoa[];
  public resposta: string;
  public PretSal: Array<any>;
  public NivHie: Array<any>;
  public EstCiv: Array<any>;
  public Gen: Array<any>;
  public mensagem: string;
  public esconder: boolean = true;
  private subjectPesquisa: Subject<string> = new Subject<string>();
  public optionsCidades: Array<any>;
  public citys: Observable<Cidade[]>;
  public formacao: Array<any>;
  public formacaoTipo: Array<any>;
  public citys2: Cidade[];
  public citys22: Cidade[];
  public cidade: Observable<Cidade[]>;
  public campoRetorno: string;
  public now: Date;
  vaga: string = "";
  public que: Array<any>;
  public mostrarQuestoes: boolean = false;
  public excluirDesc: string;
  public excluirID: string;
  public mostrarBtnNovoQuest:boolean = false;
  public mostrarQuestEdicao:boolean = false;
  public resp: Array<any>;
  public mostrarRespostas: boolean = false;
  public metodoExclusao: string;
  public editaResposta: boolean = false;
  public mostrarBtnNovoResp: boolean = true;
  public RespostaCorretachk:boolean = true;;
  public mostrarSalvarRetornar: boolean = true;
  public mostrarSelQuestoes: boolean = false;
  public mostrarQuestao: boolean = false;
  public mostrarBtnQuestao: boolean = true;
  public QuestNumero: string;
  public QuestDesc: string;
  public QuestQt: string;


  @ViewChild('formPessoa') public f: FormGroup
  constructor(private route: ActivatedRoute,
    private router: Router,
    private itemService: ItemService,
    private loginService: LoginService,
    private mensagemService: MensagemService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.now = new Date
    this.QuestionarioID = this.route.snapshot.params['id'];
    if (this.QuestionarioID == undefined) {
      this.QuestionarioID = "";
    }
    if (this.QuestionarioID=='') this.mostrarBtnQuestao=false;

    this.ObterQuestionario();

    this.criarFormularioDeUsuario();
    /*this.citys = this.subjectPesquisa //retorno ofertas[]
      .pipe(
        debounceTime(1000), //executa a acao do switchMap apos 1 seg
        distinctUntilChanged(),  //executa o metodo de hover uma mudança na digitacao
        switchMap((termo: string) => {  //executa apenas o ultimo subject
          if (termo.trim() === '') {
            return of<Cidade[]>([])  //retornar observable de array de Oferta vazio impede pesquisa com valor nulo
          }
          this.esconder = true
          return this.itemService.pesquisaCidades(termo);
        }),
        catchError(() => {
          //console.log(err)
          return of<Cidade[]>([]) //retorna array vazio graças a um erro
        })
      )

    this.citys.subscribe((cids: Cidade[]) => {
      if (this.campoRetorno == 'CidadeNascimento') {
        this.citys2 = cids
        this.citys22 = []
      } else {
        this.citys22 = cids
        this.citys2 = []
      }
    })*/

  }

  public RetornaCep(cep: string): void {
    this.itemService.getCidadePorCep(cep)
      .then((cid: Cidade[]) => {
        this.formPessoa.controls['EnderecoEndereco'].setValue(cid['Endereco'])
        this.formPessoa.controls['EnderecoBairro'].setValue(cid['Bairro'])
        this.formPessoa.controls['EnderecoCIDADE'].setValue(cid['CidEst'])
        this.formPessoa.controls['EnderecoCIDADECIDADE'].setValue(cid['Cid'])
        //this.formPessoa.controls['EnderecoEndereco'].setValue(cid['Endereco'])
      })
      .catch(
        (param: any) => { console.log(param) })
      this.esconder = false
  }

ObterQuestionario(): any {
  this.itemService.getQuestionarioPorID(this.QuestionarioID)
    .then((pessoa: Quest[]) => {
      console.log('getQuestionarioPorID',pessoa)
      console.log('pessoa[questao]',pessoa['Questoes'])
      this.formPessoa.patchValue(pessoa);  //envia os dados para o form
      this.que=pessoa['Questoes']
    })
    .catch(
      () => { /*console.log(param)*/ }
    )
  }

  mostrarTblQuestoes(): any {
    this.mostrarQuestoes=true;
    this.mostrarBtnNovoQuest= true;
    this.mostrarQuestEdicao = false;
    this.mostrarSalvarRetornar=false;
    this.mostrarSelQuestoes=true;
    this.mostrarBtnQuestao=false;

    //(<FormArray>this.formPessoa.get('Formacao')).push(this.addFormacaoGrupo());
  }

  excluir(tip: string): any {
    if (this.metodoExclusao=='excluirQuestaoCache') {
      this.excluirQuestaoCache();
    }
    if (this.metodoExclusao=='excluirRespostaCache') {
      this.excluirRespostaCache();
    }
    this.excluirID=''
    this.excluirDesc=''
    this.metodoExclusao=''
  }
  /*Questoes -----------------------------------------------------------------------------------------------*/

  excluirQuestao(qid: string, qdes: string): any {
    this.excluirID=qid
    this.excluirDesc=qdes
    this.metodoExclusao='excluirQuestaoCache'
  }

  excluirQuestaoCache(): any {
    this.salvarQuestaoCache(this.excluirID,"","","","d")
  }

  editarQuestao(qid: string, novo: any, num: any, des: any): any {
    this.formPessoa.controls['DescricaoQuestao'].reset();
    this.formPessoa.controls['DescricaoQuestaoId'].reset();
    this.mostrarQuestEdicao = true;
    this.formPessoa.controls['NumeroQuestao'].setValue(num)
    this.formPessoa.controls['DescricaoQuestao'].setValue(des)
    this.formPessoa.controls['DescricaoQuestaoId'].setValue(qid)

    if (novo=='1') {
      this.mostrarBtnNovoQuest=false;
    }else{
      //this.mostrarBtnNovoQuest=true;
    }
    this.mostrarRespostas=false;
  }
  FecharManutencao(): any {
    this.mostrarQuestEdicao = false;
    this.mostrarBtnNovoQuest=true;
    this.ObterQuestionario();
    this.formPessoa.controls['DescricaoQuestao'].reset();
    this.formPessoa.controls['DescricaoQuestaoId'].reset();
  }

  salvarQuestao(): any {
    let des=this.formPessoa.get("DescricaoQuestao").value
    let qdes=this.formPessoa.get("DescricaoQuestaoId").value
    this.salvarQuestaoCache(qdes,des,"",this.QuestionarioID,"s")

}

  salvarQuestaoCache(id,desc,num,Questid,tip): any {
    let questao: Questao = new Questao (id,desc,num,Questid,tip);

    this.itemService.salvarQuestao(questao)
      .subscribe((mens: any) => {
        this.mensagem = mens;
        if (mens.ok) {
          this.mensagemService.Mensagens('Ok Questão Salva com Sucesso!!!', 1)
          this.mostrarQuestEdicao = false;
          this.ObterQuestionario();
          //this.router.navigate(['/'])
        } else {
          console.log('erro',mens)
          this.mensagemService.Mensagens(mens.Mensagem, 2)
          this.mensagem = ''
        }
      },
        ((erro: any) => this.mensagemService.Mensagens(erro, 2)))
  }

  fecharQuestoes(): any {
    this.mostrarBtnQuestao=true;
    this.mostrarBtnNovoQuest= false;
    this.mostrarQuestEdicao = false;
    this.mostrarSalvarRetornar=true;
    this.mostrarSelQuestoes=false;
    this.mostrarQuestao=false;

    //(<FormArray>this.formPessoa.get('Formacao')).push(this.addFormacaoGrupo());
  }
  /*Questoes Final -----------------------------------------------------------------------------------------------*/



/*Respostas -----------------------------------------------------------------------------------------------*/
  MostrarRespostas(qid: string, num: string, de: string, qt: string): any {

    this.ObterRespostas(qid,'');
    this.mostrarRespostas=true
    this.QuestaoID=qid
    this.mostrarBtnNovoQuest=false;
    this.mostrarSelQuestoes=false;
    this.mostrarQuestao=true;
    this.QuestNumero=num;
    this.QuestDesc=de;
    this.QuestQt=qt;

  }

  ObterRespostas(qid: string, respid: string): any {
    this.itemService.getRespostas(qid)
      .then((resposta: Respo[]) => {
        //this.formPessoa.patchValue(pessoa);  //envia os dados para o form
        if (respid==='') this.resp=resposta
      })
      .catch(
        () => { /*console.log(param)*/ }
      )
    }

    editarResposta(rid: string, novo: any, num: any, des: any, cor: any): any {
      this.formPessoa.reset({DescricaoResposta: '', RespostaCorreta: ''});

      if (novo=='1') {
        this.mostrarBtnNovoResp=false;
        cor=false;
        this.RespostaCorretachk=false
      }else{
        this.mostrarBtnNovoResp=true;
        this.RespostaCorretachk=cor;
      }
        //alert('RespostaCorreta:'+cor)
        this.editaResposta = true;
        this.formPessoa.controls['NumeroResposta'].setValue(num);
        this.formPessoa.controls['DescricaoResposta'].setValue(des);
        this.formPessoa.controls['RespostaCorreta'].setValue(cor);
        this.formPessoa.controls['RespostaId'].setValue(rid);
      }

  excluirReposta(qid: string, qdes: string): any {
      this.excluirID=qid
      this.excluirDesc=qdes
      this.metodoExclusao='excluirRespostaCache'
  }

  excluirRespostaCache(): any {
      this.salvarRespostaCache(this.excluirID,"","","","","d",'')
  }

  salvarResposta(): any {
    let des=this.formPessoa.get("DescricaoResposta").value
    let cor=this.formPessoa.get("RespostaCorreta").value
    let id=this.formPessoa.get("RespostaId").value
    this.salvarRespostaCache(id,des,"",this.QuestaoID,cor,"s",'')

  }
  salvarRespostaCache(id,desc,num,Questid,Correta,tip,sel): any {
    let res: Respo = new Respo (id,desc,num,Correta,Questid,tip,sel);

    this.itemService.salvarResposta(res)
      .subscribe((mens: any) => {
        this.mensagem = mens;
        if (mens.ok) {
          this.mensagemService.Mensagens('Ok Resposta Salva com Sucesso!!!', 1)
          this.editaResposta = false;
          this.mostrarBtnNovoResp=true;
          this.ObterRespostas(this.QuestaoID,'');
          //this.router.navigate(['/'])
        } else {
          console.log('erro',mens)
          this.mensagemService.Mensagens(mens.Mensagem, 2)
          this.mensagem = ''
        }
      },
        ((erro: any) => this.mensagemService.Mensagens(erro, 2)))
  }

  FecharResposta(): any {
    this.editaResposta = false;
    this.mostrarRespostas = false;
    this.editaResposta = false;
    this.mostrarBtnNovoQuest=true;
    this.mostrarSelQuestoes=true;
    this.mostrarQuestao=false;
    this.ObterQuestionario();
}
/*Respostas  Final--------------------------------------------------------------------------------------------*/


  addFormacaoGrupo(): FormGroup {
    return this.fb.group({
      fTipo: [''],
      fDesc: [''],
      fMesF: [''],
      fMesI: [''],
      fid: ['']
    })
  }
  addLanguageGroup(): FormGroup {
    return this.fb.group({
      Idi: [''],
      Idiv: [''],
      IdiSelecionada: ['']
    })
  }

  public Retornar(): void {
      this.router.navigate(['/questionario'])
  }

  public pesquisa(termoDaBusca: string, campo: string): void {
    if (termoDaBusca.indexOf('/') < 0) {
      this.campoRetorno = campo
      this.subjectPesquisa.next(termoDaBusca)
    }
  }

  public limpaPesquisa(res: string, id: string, campo: string): void {
    if (campo == 'CidadeNascimento') {
      this.pessoa['CidadeNascimento'] = res
      this.pessoa['CidadeNascimentoid'] = id
      this.formPessoa.controls['CidadeNascimento'].setValue(res) //setar campo tela html
      this.formPessoa.controls['CidadeNascimentoid'].setValue(id) //setar campo tela html
    } else {
      this.pessoa['EnderecoCIDADE'] = res
      this.pessoa['EnderecoCIDADECIDADE'] = id
      this.formPessoa.controls['EnderecoCIDADE'].setValue(res)
      this.formPessoa.controls['EnderecoCIDADECIDADE'].setValue(id)
    }
    //this.formPessoa.patchValue(this.pessoa) //refresh na tela movendo todo o objeto
    //this.subjectPesquisa.next('')
    //this.citys=this.itemService.pesquisaCidades('xyz')
    this.esconder = false
  }
  enviarDados(f: FormGroup): void {
    let ques: Quest = new Quest (f.value.id, f.value.Descricao, f.value.Empresa,  f.value.Codigo, f.value.Data, f.value.Situacao, f.value.Cabecalho, f.value.Questoes, f.value.Respostas);

    this.itemService.postQuest(ques)
      .subscribe((mens: any) => {
        this.mensagem = mens;
        if (mens.ok) {
          this.mensagemService.Mensagens('Ok Questionario Salvo com Sucesso!!!', 1)
          this.mostrarBtnQuestao=true
          //this.router.navigate(['/'])
        } else {
          console.log('erro',mens)
          this.mensagemService.Mensagens(mens.Mensagem, 2)
          this.mensagem = ''
        }
      },
        ((erro: any) => this.mensagemService.Mensagens(erro, 2)))
  }
  criarFormularioDeUsuario() {
    this.formPessoa = this.fb.group({
      id: [''],
      Descricao: [''],
      Codigo: [''],
      Empresa: [''],
      Data: [''],
      Situacao: [''],
      QtdQuestoes: [''],
      DescricaoQuestao: [''],
      DescricaoQuestaoId: [''],
      NumeroQuestao: [''],
      NumeroResposta: [''],
      DescricaoResposta: [''],
      RespostaCorreta: [''],
      RespostaId: [''],
    });
  }

  isLoggedIn(): boolean{
      //alert(this.loginService.user)
      let IsLog: boolean =this.loginService.isLoggedIn()
      /*if (this.IsLog && !this.IsLogAnt){
        this.IsLogAnt=this.IsLog
        this.CarregaCabecalho()
      }*/
      return IsLog
    }

  // Propriedades do formulário utilizadas para obter os erros
  get Cpf() {
    return this.formPessoa.get('Cpf');
  }

  get Nascimento() {
    return this.formPessoa.get('Nascimento');
  }
  public backToTop() {
    $('#Nome').focus();
  }
  // getVaga(){
  // }


}
