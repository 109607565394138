import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';


import { ItemService } from '../service';
import { MensagemService } from '../mensagem/mensagem.service';
import { Selecoes } from '../shared/PadraSelecoes.model';
import { Pess } from '../shared/PadraoPessoas.model';
import * as $ from 'jquery';


@Component({
  selector: 'app-selecao',
  templateUrl: './selecao.component.html',
  styleUrls: ['./selecao.component.css'],
  providers: [ItemService, MensagemService]
})

@Injectable()
  export class SelecaoComponent implements OnInit {
  public formPessoa: FormGroup;
  public SelecaoID: string
  public mostrarSelecoes: boolean=true
  public sel: Selecoes[];
  public QuestTodos: Array<any>;
  public mensagem: string;
  public Situacaochk: boolean=true;
  public mostrarPessoas: boolean=false;
  public editarPessoas: boolean=true;
  public mostrarEditarPessoas: boolean=false;
  public selecaoDesc: string
  public pessoas: Array<any>;
  public pes: Pess[];
  public sidSelecionado: string
  public selPid: string;
  public modalPergunta: string;
  public modalMensagem: string;
  public modalOpcao: string;
  public modalBotao: string;
  public modalsid: string;
  public modaldes: string;
  public modalBotaoHid: boolean=false;

  public MostrarSpinner: boolean=true;


  constructor(private route: ActivatedRoute,
    private router: Router,
    private itemService: ItemService,
    private mensagemService: MensagemService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.SelecaoID = this.route.snapshot.params['sid'];
    if (this.SelecaoID == undefined) {
      this.SelecaoID = "";
    }
    //if (this.SelecaoID=='') this.mostrarBtnQuestao=false;

    this.ObterSelecoes("1","0");

    this.criarFormularioSelecao();
  }

  ObterSelecoes(sit: string, sid: string): any {
    this.itemService.getSelecoes(sit,sid)
      .then((sel: Selecoes[]) => {
        this.formPessoa.patchValue(sel);  //envia os dados para o form
        this.sel=sel
        this.QuestTodos=sel[0].Questionarios
      })
      .catch(
        () => { /*console.log(param)*/ }
      )
    }

    criarFormularioSelecao() {
      this.formPessoa = this.fb.group({
        sid: [''],
        Descricao: [''],
        Empresa: [''],
        DataCadastro: [''],
        Situacao: [''],
        QtdPessoas: [''],
        TempoProva: [''],
        Questionario: [''],
        Tipo: [''],
        DataMaxima: ['']
      });
    }

    Acessar(ret) {
        this.ObterSelecoes(ret,"0");
        this.mostrarPessoas=false;
        this.editarPessoas=true;
      }

    editarSelecao(novo: any, sid: string , dat: any, des: any,que: any,emp: any,sit: any,tem: any,datm: any): any {
      if (novo=='d') {
        this.modalPergunta='Deseja excluir a Seleção ?'
        this.modalMensagem="Seleção: "+des
        this.modalOpcao="d"
        this.modalBotao='Excluir'
        this.modalBotaoHid=false;
        this.formPessoa.controls['Tipo'].setValue('D')
        this.formPessoa.controls['sid'].setValue(sid)
        /*let ret=confirm("Deseja excluir a Seleção: "+des+" ?")
        if (ret) {
          this.formPessoa.controls['Tipo'].setValue('D')
          this.salvarSelecao(this.formPessoa)
        }*/
        return;
      }

      this.mostrarSelecoes = false;
      this.mostrarPessoas=false;
      this.formPessoa.controls['sid'].setValue(sid)
      this.formPessoa.controls['Descricao'].setValue(des)
      this.formPessoa.controls['Empresa'].setValue(emp)
      this.formPessoa.controls['TempoProva'].setValue(tem)
      this.formPessoa.controls['DataMaxima'].setValue(datm)


      if (novo=='0') {
        this.Situacaochk=sit
        let esci = ''
        let j=0
        console.log('que:'+que)
        for (j == 0; j <= this.QuestTodos.length; j++) {
          let obj = this.QuestTodos[j]
          for (var key in obj) {
            let attrName = key;
            let attrValue = obj[key];
            //alert(attrName+'---'+attrValue)
            if (attrName == 'qid' && attrValue == que){
               esci = attrValue;
              break;
            }

            //if (attrName == 'Selecionado' && attrValue == true) esci = val            console.log(attrName,attrValue)
          }
        }

        if (esci != '') this.formPessoa.controls['Questionario'].setValue(esci)

      }else if (novo=='1'){
        this.formPessoa.reset({sid: '', Descricao: '', Empresa: '', TempoProva: '', Questionario: ''});
        this.Situacaochk=true;
        sit=true;
      }
      this.formPessoa.controls['Situacao'].setValue(sit)

    }

    FecharManutencao() {
          this.mostrarSelecoes = true;
      }

    salvarSelecao(f: FormGroup): void {
      let pes: Selecoes =
        new Selecoes(
          f.value.sid,f.value.Descricao,f.value.DataCadastro,f.value.Questionario,f.value.QuestionarioId,f.value.Empresa,f.value.Situacao,f.value.TempoProva,f.value.QtdPessoas,f.value.Questionarios,f.value.Tipo,f.value.DataMaxima,f.value.pessoas);
          this.itemService.salvarSelecao(pes)
            .subscribe((mens: any) => {
              this.mensagem = mens;
              if (mens.ok) {
                this.mensagemService.Mensagens(mens.Mensagem, 1)
                this.mostrarSelecoes = true;
                this.ObterSelecoes("1","0");
                //this.router.navigate(['/'])
              } else {
                console.log('erro',mens)
                this.mensagemService.Mensagens(mens.Mensagem, 2)
                this.mensagem = ''
              }
            },
            ((erro: any) => this.mensagemService.Mensagens(erro, 2)))
      }

  MostrarPessoas(sid: string, des: string) {
        this.mostrarPessoas = true;
        this.editarPessoas=true;
        this.selecaoDesc=des;
        this.sidSelecionado=sid
        let j=0;
        for (j == 0; j <= this.sel.length; j++) {
          let obj = this.sel[j]
          for (var key in obj) {
            let attrName = key;
            let attrValue = obj[key];
            if (attrName == 'sid' && attrValue == sid){
               this.pessoas=this.sel[j].Pessoas
              break;
            }


          }
    }

  }

  editarPessoa(){
    this.itemService.getCandidatos(this.sidSelecionado)
      .then((pe: Pess[]) => {
        this.pes=pe
      })
      .catch(
        () => { /*console.log(param)*/ }
      )
      this.editarPessoas=false;
  }


  acrescentarPessoa(tip: string, pessId: string): void {
        this.itemService.acrescentarPessoa(this.sidSelecionado,this.selPid,tip,pessId)
          .subscribe((mens: any) => {
            this.mensagem = mens;
            if (mens.ok) {
              this.mensagemService.Mensagens(mens.Mensagem, 1)
              this.mostrarPessoas = false;
              this.ObterSelecoes("1","0");
              this.editarPessoas=true
              //this.router.navigate(['/'])
            } else {
              console.log('erro',mens)
              this.mensagemService.Mensagens(mens.Mensagem, 2)
              this.mensagem = ''
            }
          },
          ((erro: any) => this.mensagemService.Mensagens(erro, 2)))
    }

  FecharCandidatos() {
      this.mostrarPessoas = false;
  }
  FecharNovoCandidato() {
      this.editarPessoas = true;
  }

  EnviarEmail(sid: string,des: string, enviados: number) {
    if (enviados==0){
      this.modalPergunta='Atenção Já foram enviados E-mails para todos os Candidatos!!'
      this.modalMensagem=""
      this.modalOpcao=""
      this.modalBotao=''
      this.modalsid='';
      this.modalBotaoHid=true;
    }else{
    this.modalPergunta='Posso enviar E-mail para todos os candidatos incluídos na Seleção e que ainda não enviamos?'
    this.modalMensagem="Seleção: "+des
    this.modalOpcao="e"
    this.modalBotao='Enviar'
    this.modalsid=sid;
    this.modaldes=des
    this.modalBotaoHid=false;
    }
  }

  modalMetodo(){
    if (this.modalOpcao=='d') {
      this.salvarSelecao(this.formPessoa);
      return;
    }
    if (this.modalOpcao=='e') {
      this.MostrarSpinner=false;
      this.itemService.EnviarEmail(this.modalsid,this.modaldes)
        .subscribe((mens: any) => {
          this.mensagem = mens;
          this.MostrarSpinner=true;
          if (mens.ok) {
            this.mensagemService.Mensagens(mens.Mensagem, 1)
            //this.router.navigate(['/'])
          } else {
            this.mensagemService.Mensagens(mens.Mensagem, 2)
            this.mensagem = ''
          }
        },
        ((erro: any) => this.mensagemService.Mensagens(erro, 2)))

      return;
    }
  }
}
