import { Item } from './relatorio.Item';

export const ITEMS: Item[] = [
    {
        name:'Item 1',
        value:'item_1'
     },
     {
         name:'Item 2',
         value:'item_2'
      },
      {
          name:'Item 3',
          value:'item_3'
       },
       {
           name:'Item 4',
           value:'item_4'
        },
        {
            name:'Item 5',
            value:'item_5'
         }
];
