import { Component, OnInit } from '@angular/core'
import { Quest } from '../shared/PadraoQuest.model'
import { ItemService } from '../service'
import { ITEMS } from '../shared/relatorios.ITEMS';
import { Item } from '../shared/relatorio.item';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CabDet } from '../shared/PadraoRelCabDet.model';
import {LoginService} from '../login/login.service'

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html',
  styleUrls: ['./questionario.component.css'],
  providers: [ItemService]
})
export class QuestionarioComponent implements OnInit {
  radioSel: any;
  radioSelected: string;
  radioSelectedString: string;

  public cab: Quest[];
  public dados: "";
  public Emp: number = 111
  public formCurriculum: FormGroup;
  public ret: string
  public loading: boolean
  public cabdet: CabDet
  public cabf: Array<object>;

  public det: object
  public retSit: number
  public Situacao = [
    { Situacao: "Novo" },
    { Situacao: "Visto" },
    { Situacao: "Desprezado" },
    { Situacao: "Aproveitado" }
  ]
  public Genero = [
    { Genero: "Masculino" },
    { Genero: "Feminino" }
  ];

  itemsList: Item[] = ITEMS;
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  checked: boolean;

  constructor(  private itemService: ItemService,
    private fb: FormBuilder,
    private loginService: LoginService) {
    this.itemsList = ITEMS
    this.getSelecteditem();
  }

  ngOnInit() {
      this.formCurriculum = this.fb.group({
        Cidade: this.fb.control('', [Validators.minLength(3)]),
        IdadeA: this.fb.control('', [Validators.minLength(2)]),
        IdadeB: this.fb.control('', [Validators.minLength(2)]),
        Situacao: this.fb.control('', []),
        Genero: this.fb.control('', []),
      })
      this.Situacao = this.Situacao

      this.Genero = this.Genero
      this.carregarTabela(1);
    }
    carregarTabela(retSit) {
      //alert('carregarTabela:'+retSit);

      this.itemService.getCarregarQuestPorSit(retSit)
        .then((cab: Quest[]) => {
          this.cab = cab
          this.setPage(1)
        })
        .catch(
          (param: any) => { console.log("Detalhes do erro: ", param) }
        )
    }

    NovoQuestionario(retSit) {
      //alert('carregarTabela:'+retSit);

      this.itemService.getCarregarQuestPorSit(retSit)
        .then((cab: Quest[]) => {
          this.cab = cab
          this.setPage(1)
        })
        .catch(
          (param: any) => { console.log("Detalhes do erro: ", param) }
        )
    }


    getSelecteditem() {
      this.radioSel = ITEMS.find(Item => Item.value === this.radioSelected);
      this.radioSelectedString = JSON.stringify(this.radioSel);
    }
    Acessar(ret) {

        this.carregarTabela(ret);
        /*
      const cidade: string = this.formCurriculum.value.Cidade
      const idadeA: number = this.formCurriculum.value.IdadeA
      const idadeB: number = this.formCurriculum.value.IdadeB
      const situacao: string = this.formCurriculum.value.Situacao
      const genero: string = this.formCurriculum.value.Genero
      const filter = [cidade, idadeA, idadeB, situacao, genero];

      console.log("Filtro: ", filter);

      if (!cidade && !idadeA && !idadeB && !situacao && !genero) {
        this.cabf = this.cab
        return
      }
      if (idadeA && !idadeB || idadeB && !idadeA || idadeA > idadeB) {
        this.cabf = this.cab
        return
      }

      const tabfil = this.cab.filter(dados => {
        if (cidade) {
          if (dados.Cidade.toUpperCase() == cidade.toUpperCase()) {
            return dados
          }
        }
        if (cidade && idadeA && idadeB && situacao && genero) {
          if (dados.Cidade == cidade && dados.Idade >= idadeA && dados.Idade <= idadeB && dados.Situacao == situacao && dados.Genero == genero) {
            return dados
          }
        }
        if (idadeA && idadeB && situacao) {
          if (dados.Idade >= idadeA && dados.Idade <= idadeB && dados.Situacao == situacao) {
            return dados
          }
        }
        if (idadeA && idadeB && genero) {
          if (dados.Idade >= idadeA && dados.Idade <= idadeB && dados.Genero == genero) {
            return dados
          }
        }
        if (idadeA && idadeB && !cidade && !situacao) {
          if (dados.Idade >= idadeA && dados.Idade <= idadeB) {
            return dados
          }
        }
        if (situacao && genero) {
          if (dados.Situacao == situacao && dados.Genero == genero) {
            return dados
          }
        }
        if (situacao) {
          if (dados.Situacao == situacao) {
            return dados
          }
        }
        if (genero) {
          if (dados.Genero == genero) {
            return dados
          }
        }
      });
      if (!tabfil) return
      this.cabf = tabfil;
      console.log(this.cab)
      console.log(tabfil);
      this.loading = true;
      */
    }
    setPage(page: number) {
      // get pager object from service
      this.pager = this.getPager(this.cab.length, page);

      // get current page of items
      this.pagedItems = this.cab.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
    deSelectRadio() {
    }
    getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {

      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);
      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }

      let startPage: number, endPage: number;
      if (totalPages <= 10) {
        // less than 10 total pages so show all
        startPage = 1;
        endPage = totalPages;
      } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 6) {
          startPage = 1;
          endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
          startPage = totalPages - 9;
          endPage = totalPages;
        } else {
          startPage = currentPage - 5;
          endPage = currentPage + 4;
        }
      }

      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

      // return object with all pager properties required by the view
      return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
      };
    }

    isLoggedIn(): boolean{
        //alert(this.loginService.user)
        let IsLog: boolean =this.loginService.isLoggedIn()
        /*if (this.IsLog && !this.IsLogAnt){
          this.IsLogAnt=this.IsLog
          this.CarregaCabecalho()
        }*/
        return IsLog
      }

  }
