import { Component, OnInit, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, far, fab);
//import * as $ from 'jquery'; para importar o javascript

import {LoginMod} from '../shared/PadraoLogin.model'
import {MensagemService} from '../mensagem/mensagem.service'
import {LoginService} from '../login/login.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MensagemService, LoginService]
})

@Injectable()
export class LoginComponent implements OnInit {
  public formLogin: FormGroup
  public formNovoUsuario: FormGroup
  public mensagem: string = ''
  public User: LoginMod
  public valido: boolean
  public mod: string = ''
  public Usu: LoginMod
  public validaSenhas: boolean = false
  model: any = {};
   loading = false;
   returnUrl: string;

  constructor(private fb: FormBuilder,
              private mensagemService: MensagemService,
              private loginService: LoginService,
              private route: ActivatedRoute,
              private router: Router
            ) { }

  ngOnInit() {
    this.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.formLogin = this.fb.group({
      login: this.fb.control('', [Validators.required, Validators.minLength(4)]),
      senha: this.fb.control('', [Validators.required, Validators.minLength(6)])
    })
    this.formNovoUsuario = this.fb.group({
      loginNv: this.fb.control('', [Validators.required, Validators.minLength(4)]),
      senhaNv: this.fb.control('', [Validators.required, Validators.minLength(6)]),
      senhaConfNv: this.fb.control('', [Validators.required, Validators.minLength(6)]),
      nomeNv: this.fb.control('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]),
      cpfNv: this.fb.control('', [Validators.required, Validators.minLength(14)])
    })
  }

  login(): void {
      this.loading = true;
      this.loginService.login(this.formLogin.value.login,
                            this.formLogin.value.senha)
                              .subscribe((user: LoginMod) => {
                                this.User = user
                                this.mensagem=this.User.mensagem
                                localStorage.setItem('userNome', this.User.userNome);
                                localStorage.setItem('acessToken', this.User.acessToken);
                                localStorage.setItem('userId', this.User.userId);
                                localStorage.setItem('mensagem', this.User.mensagem);
                                if (this.User.valido){
                                  localStorage.setItem('valido', '1');
                                }else{
                                  localStorage.setItem('valido', '0');
                                }
                                this.valido=this.User.valido
                                //this.router.navigateByUrl(this.returnUrl);
                                if (this.User.valido) {
                                  this.mensagemService.Mensagens(this.User.mensagem, 1)
                                  let element = document.getElementById("CloseButton") as any;
                                  element.click();
                                  this.formLogin.reset();
                                  this.formNovoUsuario.reset();
                               }else{
                                // this.mensagemService.Mensagens(this.User.mensagem, 2)
                               }
                                /*
                                this.loginService.changeMessage(this.User[0])
                                if (this.User[0].valido) {
                                  let element = document.getElementById("CloseButton") as any;
                                  //element.click();
                                  this.delay(20).then(any=>{
                                       element.click()
                                       let lo = document.getElementById("idLogin") as any;
                                       lo.value=''
                                       let se = document.getElementById("idSenha") as any;
                                       se.value=''
                                       this.mensagem=''
                                       this.valido=false
                                       //this.User[0]=''
                                       this.formLogin.reset();

                                       let element2 = document.getElementById("txtMensagem") as any;
                                       element2.value=this.User[0].mensagem //'Ok Pessoa Salva com sucesso!!!'
                                       let element1 = document.getElementById("btnMensagem") as any;
                                       element1.click();

                                       this.mensagemService.delay(2000,'Ok Pessoa Salva com sucesso!!!').then(any=>{element1.click()})

                                  }); //agurada 3 segundos e fecha o modal simulando um click no botao
                                }
                                //if (mens==='Ok Pessoa Salva com Sucesso ID: '){
                                  //alert('Ok Pessoa Salva com sucesso!!!')
                                  //this.mensagemService.notify('Ok Pessoa Salva com sucesso!!!')
                                //}else{
                                //}
                                */},
                                ((erro:any) => console.log('erro postPessoa:', erro.status)),
                                () => console.log('Login Completo'),
                                )
                                this.loading = false;
                       /*.subscribe(user => this.notificationService.notify(`Bem vindo, ${user.nome}`),
                                  response => //HttpErrorResponse
                                          this.notificationService.notify(response.error.message),
                                  ()=> {
                                    //this.router.navigate([atob(this.navigateTo)])
                                  })//.subscribe(user => console.log(user))*/

    }

    async delay(ms: number) {
       await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
    }

  logout(){
    //this.formLogin.reset();
    localStorage.clear()
    this.User = undefined

  }

  salvarUsuario(): void {
    console.log("salvarUsuario")
      this.loginService.salvarUsuario(this.formNovoUsuario.value.loginNv,
                        this.formNovoUsuario.value.senhaNv,this.formNovoUsuario.value.senhaConfNv,
                        this.formNovoUsuario.value.nomeNv,this.formNovoUsuario.value.cpfNv)
                              .subscribe((user: any) => {
                                  this.mensagemService.Mensagens('Ok Novo usuário salvo com Sucesso!!', 1)
                                  let element = document.getElementById("CloseButton") as any;
                                  element.click();
                                  this.formNovoUsuario.reset();

                             },
                             ((erro:any) => this.mensagemService.Mensagens(erro.error.errors, 2)),
                             () => console.log('salvarUsuario Completo')
                             )
  }


  esconderCollapse(m: string): void{
    let element = document.getElementById("Esquecido") as any;
    let element1 = document.getElementById("demo01") as any;

    if (m=='demo01' && element1.getAttribute( 'class' )=="collapse show"){
       element1.setAttribute("class", "collapse");
     }
    if (m=='Esquecido' && element.getAttribute( 'class' )=="collapse show") {
      element.setAttribute("class", "collapse");
    }
    this.formNovoUsuario.reset();
  }

  equalsTo(): boolean{
      const s1: string = this.formNovoUsuario.value.senhaNv
      const s2 =this.formNovoUsuario.value.senhaConfNv

      this.validaSenhas=false
      if (!s1 || !s2) {
        this.validaSenhas= false
      }else{
        if (s1 === s2){
          this.validaSenhas= true
        }
      }
      console.log(s1,s2,this.validaSenhas)
      return this.validaSenhas
  }
  limparMensagem(): void{
    this.mensagem=''
  }
}
