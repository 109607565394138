import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, catchError } from 'rxjs/operators';

import { Pessoa } from '../shared/PadroesEdicaoPessoa.model';
import { ItemService } from '../service';
import { MensagemService } from '../mensagem/mensagem.service';
import { Cidade } from '../shared/PadraoCidade.model';
import { Validacoes } from './curriculum_validacoes';

import * as $ from 'jquery';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.css'],
  providers: [ItemService, MensagemService]
})
// Material Select Initialization

@Injectable()
export class CurriculumComponent implements OnInit {
  public formPessoa: FormGroup;
  public tIdioma: any;
  public Idioma: Array<any>;
  public PessoaID: string;
  public pessoa: Pessoa[];
  public resposta: string;
  public PretSal: Array<any>;
  public NivHie: Array<any>;
  public EstCiv: Array<any>;
  public Gen: Array<any>;
  public mensagem: string;
  public esconder: boolean = true;
  private subjectPesquisa: Subject<string> = new Subject<string>();
  public optionsCidades: Array<any>;
  public citys: Observable<Cidade[]>;
  public formacao: Array<any>;
  public formacaoTipo: Array<any>;
  public citys2: Cidade[];
  public citys22: Cidade[];
  public cidade: Observable<Cidade[]>;
  public campoRetorno: string;

  vaga: string = "";


  @ViewChild('formPessoa') public f: FormGroup
  constructor(private route: ActivatedRoute,
    private router: Router,
    private itemService: ItemService,
    private mensagemService: MensagemService,
    private fb: FormBuilder) { }

  ngOnInit() {

    this.PessoaID = this.route.snapshot.params['id'];
    if (this.PessoaID == undefined) {
      this.PessoaID = "";
    }
    this.itemService.getPessoaPorId(this.PessoaID)
      .then((pessoa: Pessoa[]) => {
        pessoa['CargoInteresse'] = this.route.snapshot.params['cod'];
        this.pessoa = pessoa
        this.Idioma = pessoa['Idioma'];
        for (this.tIdioma in this.Idioma) { }
        let i = 0
        let j = 0
        for (i == 1; i <= this.tIdioma; i++) {
          (<FormArray>this.formPessoa.get('Idioma')).push(this.addLanguageGroup());
        }
        this.PretSal = pessoa['PretensaoSalarial']
        this.formacao = pessoa['Formacao']
        j = 1
        for (j == 1; j <= this.formacao.length - 1; j++) {
          this.addButtonClick()
        }
        this.formacaoTipo = pessoa['FormacaoTipo']
        this.NivHie = pessoa['NivelHierarquico']
        this.EstCiv = pessoa['EstadoCivil']
        this.Gen = pessoa['Genero']
        this.formPessoa.patchValue(pessoa);  //envia os dados para o form

        let val = ''
        let esci = ''
        for (j == 0; j <= this.EstCiv.length; j++) {
          let obj = this.EstCiv[j]
          for (var key in obj) {
            let attrName = key;
            let attrValue = obj[key];
            if (attrName == 'ECv') val = attrValue
            if (attrName == 'ECSelecionada' && attrValue == true) esci = val
          }
        }
        if (esci != '') this.formPessoa.controls['EstadoCivil'].setValue(esci)

        esci = ''
        j = 0
        for (j == 0; j <= this.Gen.length; j++) {
          let obj = this.Gen[j]
          for (var key in obj) {
            let attrName = key;
            let attrValue = obj[key];
            if (attrName == 'Generov') val = attrValue;
            if (attrName == 'GeneroSelecionada' && attrValue == true) esci = val;
          }
        }
        if (esci != '') this.formPessoa.controls['Genero'].setValue(esci);

        esci = '';
        j = 0;
        for (j == 0; j <= this.NivHie.length; j++) {
          let obj = this.NivHie[j];
          for (var key in obj) {
            let attrName = key;
            let attrValue = obj[key];
            if (attrName == 'NIv') val = attrValue;
            if (attrName == 'NISelecionada' && attrValue == true) esci = val;
          }
        }
        if (esci != '') this.formPessoa.controls['NivelHierarquico'].setValue(esci);

        esci = '';
        j = 0
        for (j == 0; j <= this.PretSal.length; j++) {
          let obj = this.PretSal[j];
          for (var key in obj) {
            let attrName = key;
            let attrValue = obj[key];
            if (attrName == 'PSv') val = attrValue;
            if (attrName == 'PSSelecionada' && attrValue == true) esci = val;
          }
        }
        if (esci != '') this.formPessoa.controls['PretensaoSalarial'].setValue(esci);
      })
      .catch(
        () => { /*console.log(param)*/ }
      )

    this.criarFormularioDeUsuario();
    this.citys = this.subjectPesquisa //retorno ofertas[]
      .pipe(
        debounceTime(1000), //executa a acao do switchMap apos 1 seg
        distinctUntilChanged(),  //executa o metodo de hover uma mudança na digitacao
        switchMap((termo: string) => {  //executa apenas o ultimo subject
          if (termo.trim() === '') {
            return of<Cidade[]>([])  //retornar observable de array de Oferta vazio impede pesquisa com valor nulo
          }
          this.esconder = true
          return this.itemService.pesquisaCidades(termo);
        }),
        catchError(() => {
          //console.log(err)
          return of<Cidade[]>([]) //retorna array vazio graças a um erro
        })
      )

    this.citys.subscribe((cids: Cidade[]) => {
      if (this.campoRetorno == 'CidadeNascimento') {
        this.citys2 = cids
        this.citys22 = []
      } else {
        this.citys22 = cids
        this.citys2 = []
      }
    });
  }

  public RetornaCep(cep: string): void {
    this.itemService.getCidadePorCep(cep)
      .then((cid: Cidade[]) => {
        this.formPessoa.controls['EnderecoEndereco'].setValue(cid['Endereco'])
        this.formPessoa.controls['EnderecoBairro'].setValue(cid['Bairro'])
        this.formPessoa.controls['EnderecoCIDADE'].setValue(cid['CidEst'])
        this.formPessoa.controls['EnderecoCIDADECIDADE'].setValue(cid['Cid'])
        //this.formPessoa.controls['EnderecoEndereco'].setValue(cid['Endereco'])
      })
      .catch(
        (param: any) => { console.log(param) })
      this.esconder = false
  }
  addButtonClick(): any {
    (<FormArray>this.formPessoa.get('Formacao')).push(this.addFormacaoGrupo());
  }
  addFormacaoGrupo(): FormGroup {
    return this.fb.group({
      fTipo: [''],
      fDesc: [''],
      fMesF: [''],
      fMesI: [''],
      fid: ['']
    })
  }
  addLanguageGroup(): FormGroup {
    return this.fb.group({
      Idi: [''],
      Idiv: [''],
      IdiSelecionada: ['']
    })
  }

  public pesquisa(termoDaBusca: string, campo: string): void {
    if (termoDaBusca.indexOf('/') < 0) {
      this.campoRetorno = campo
      this.subjectPesquisa.next(termoDaBusca)
    }
  }

  public limpaPesquisa(res: string, id: string, campo: string): void {
    if (campo == 'CidadeNascimento') {
      this.pessoa['CidadeNascimento'] = res
      this.pessoa['CidadeNascimentoid'] = id
      this.formPessoa.controls['CidadeNascimento'].setValue(res) //setar campo tela html
      this.formPessoa.controls['CidadeNascimentoid'].setValue(id) //setar campo tela html
    } else {
      this.pessoa['EnderecoCIDADE'] = res
      this.pessoa['EnderecoCIDADECIDADE'] = id
      this.formPessoa.controls['EnderecoCIDADE'].setValue(res)
      this.formPessoa.controls['EnderecoCIDADECIDADE'].setValue(id)
    }
    //this.formPessoa.patchValue(this.pessoa) //refresh na tela movendo todo o objeto
    //this.subjectPesquisa.next('')
    //this.citys=this.itemService.pesquisaCidades('xyz')
    this.esconder = false
  }
  enviarDados(f: FormGroup): void {
    let pes: Pessoa =
      new Pessoa(
        f.value.id, f.value.Nome, f.value.Nascimento, f.value.Email, f.value.Cpf, f.value.Telefone,
        f.value.EstadoCivil, f.value.Genero, f.value.CidadeNascimento,
        f.value.EnderecoCep, f.value.EnderecoEndereco, f.value.EnderecoNumero,
        f.value.EnderecoComplemento, f.value.EnderecoBairro, f.value.EnderecoCIDADE,
        f.value.AreaAtuacao, f.value.CargoInteresse, f.value.NivelHierarquico, f.value.PretensaoSalarial,
        f.value.Empresa, f.value.Cargo, f.value.Entrada, f.value.Saida, f.value.Salario, f.value.PrincipaisAtividades,
        f.value.Idioma, f.value.FormacaoTipo, f.value.Formacao,
        f.value.CidadeNascimentoid,
        f.value.Senha, f.value.Cidades,
        f.value.EnderecoCIDADECIDADE, f.value.EnderecoCIDADEUF);
    this.itemService.postPessoa(pes)
      .subscribe((mens: any) => {
        this.mensagem = mens;
        if (mens.ok === 1) {
          this.mensagemService.Mensagens('Ok Curriculum Salvo com Sucesso!!!', 1)
          this.router.navigate(['/'])
        } else {
          this.mensagemService.Mensagens(mens.Mensagem, 2)
          this.mensagem = ''
        }
      },
        ((erro: any) => this.mensagemService.Mensagens(erro, 2)))
  }
  criarFormularioDeUsuario() {
    this.formPessoa = this.fb.group({
      id: [''],
      Nome: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      Nascimento: ['', Validators.compose([Validators.required, Validacoes.MaiorQue18Anos])],
      Email: ['', [Validators.required, Validators.email]],
      Cpf: ['', Validators.compose([Validators.required, Validacoes.ValidaCpf])],
      Telefone: ['', [Validators.required]],
      EstadoCivil: ['', [Validators.required]],
      Genero: ['', [Validators.required]],
      CidadeNascimento: [''],
      CidadeNascimentoid: [''],
      EnderecoCep: [''],
      EnderecoEndereco: [''],
      EnderecoNumero: [''],
      EnderecoComplemento: [''],
      EnderecoBairro: [''],
      EnderecoCIDADE: [''],
      EnderecoCIDADECIDADE: [''],
      EnderecoCIDADEUF: [''],
      Cidades: [''],
      AreaAtuacao: [''],
      CargoInteresse: [''],
      NivelHierarquico: [''],
      PretensaoSalarial: [''],
      Empresa: [''],
      Cargo: [''],
      Entrada: [''],
      Saida: [''],
      Salario: [''],
      PrincipaisAtividades: [''],
      Idioma: this.fb.array([]),
      FormacaoTipo: [''],
      Formacao: this.fb.array([
        this.addFormacaoGrupo()
      ])
    });
  }
  // Propriedades do formulário utilizadas para obter os erros
  get Cpf() {
    return this.formPessoa.get('Cpf');
  }

  get Nascimento() {
    return this.formPessoa.get('Nascimento');
  }
  public backToTop() {
    $('#Nome').focus();
  }
  // getVaga(){
  // }
}
