import { Component, OnInit, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { ItemService } from '../service';
import { MensagemService } from '../mensagem/mensagem.service';

import {Selecao} from '../shared/PadraoSelecao.model'
import {Respo} from '../shared/PadraoResposta.model'

@Component({
  selector: 'app-prova',
  templateUrl: './prova.component.html',
  styleUrls: ['./prova.component.css'],
  providers: [ItemService, MensagemService]
})

@Injectable()
export class ProvaComponent implements OnInit {
  public formProva: FormGroup;
  public SelecaoPessoaId: string;
  public que: Array<any>;
  public QuestaoDescricao: string;
  public Num: any;
  public Max: any;
  public res: Array<Respo>;
  public btnTraz: boolean = true;
  public btnFrente: boolean = true;
  public ret: Array<any>;
  public mensagem: string;
  public now: Date;
  public Avaliacao: string;
  public ApagaProva: boolean = true;
  public TempoProva: number;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private itemService: ItemService,
    private mensagemService: MensagemService,
    private fb: FormBuilder) { }

  ngOnInit() {
    alert(1);
    this.now = new Date
    this.SelecaoPessoaId = this.route.snapshot.params['id'];
    if (this.SelecaoPessoaId == undefined) this.SelecaoPessoaId = "";
    this.ObterSelecaoPessoa();
    this.criarFormularioSelecao();
    this.Num=0
    this.ret= ['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','']

  }

  ObterSelecaoPessoa(): any {
    this.itemService.getSelecaoPessoa(this.SelecaoPessoaId)
      .then((pessoa: Selecao[]) => {
        if (pessoa['ok']) {
        this.formProva.patchValue(pessoa);  //envia os dados para o form
        this.ret=pessoa
        this.que=pessoa['Questoes']
        this.Max=this.que.length-1
        this.QuestaoDescricao=this.que[this.Num].Descricao
        this.res=this.que[this.Num].Resposta
        this.TempoProva=pessoa['TempoProva']*1000
        this.functionTIMEOUT(this.TempoProva)
        }else{
        this.Avaliacao=pessoa['Mensagem'] //'Prova já Respondida ou não localizada!!!';
        this.ApagaProva=false;
        document.getElementById("openModalButton").click();
      }
      })
      .catch(
        (pessoa) => { alert('Problema no acesso à prova!!!') }
      )
    }

    criarFormularioSelecao() {
      this.formProva = this.fb.group({
        id: [''],
        Nome: [''],
        Cpf: [''],
        Questionario: [''],
        DataHora: [''],
        ok: ['']
      });
    }

    Movimenta(a: string){
      if (a=='+') this.Num=this.Num+1
      if (this.Num>this.Max) {
        this.Final();
        return;
      }
      if (a=='-') this.Num=this.Num-1
      this.res=this.que[this.Num].Resposta
      this.QuestaoDescricao=this.que[this.Num].Descricao
      this.btnFrente=true;

      if (this.Num>0) {
            this.btnTraz=false;
      }else{
            this.btnTraz=true;
      }

      let tam=this.res.length;
      let r='';
      for (var j = 0; j < tam; j++) {
        if (this.res[j].Sel) r="1";
      }
      if (r=='1' && this.Num<this.Max) this.btnFrente=false;
  }
    Avanca(b: string){
      this.que[this.Num].Resposta[b].Sel=true
      this.Num=this.Num+1
      if (this.Num>this.Max) {
        this.Final();
        return;
      }
      if (this.Num<this.Max) {
        this.btnFrente=true;
      }else{
        this.btnFrente=true;
      }
      if (this.Num>0) {
        this.btnTraz=false;
      }else{
        this.btnTraz=true;
      }
      this.res=this.que[this.Num].Resposta
      this.QuestaoDescricao=this.que[this.Num].Descricao
    }
Final(): any {
  if (this.que==undefined) return;
  this.btnTraz=true;
  this.btnFrente=true;
  let tam=this.que.length;
  let t=0;
  let res='';
  for (var i = 0; i < tam; i++) {
    let tamR=this.que[i].Resposta.length
    for (var j = 0; j < tamR; j++) {
      if (this.que[i].Resposta[j].Sel && this.que[i].Resposta[j].Correta=='1') t=t+1;
      delete this.que[i].Resposta[j].Descricao
      delete this.que[i].Resposta[j].Correta

      if (res!='') res=res+'|';
    }
  }
  this.ret['Questoes']=this.que
  let d = new Date
  this.ret['DataHora']=this.now.toLocaleString()+'[fim]'+d.toLocaleString()
  this.salvarProva(this.ret)
  }

  salvarProva(res: object): any {

    this.itemService.salvarProva(res)
      .subscribe((mens: any) => {
        this.mensagem = mens;

        if (mens.ok) {
          //this.mensagemService.Mensagens('Ok Prova Salva com Sucesso!!!', 1)
          this.Avaliacao=mens.Mensagem;
          this.ApagaProva=false;
          document.getElementById("openModalButton").click();
        } else {
          console.log('erro',mens)
          this.mensagemService.Mensagens(mens.Mensagem, 2)
          this.mensagem = ''
        }
      },
        ((erro: any) => this.mensagemService.Mensagens(erro, 2)))
  }

  functionTIMEOUT(t: any) {
    setTimeout (() => {
         this.Final();
      }, t);
  }
}
