import { Injectable } from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable, pipe} from 'rxjs'
import { tap } from 'rxjs/operators'

import {URL_API} from '../api'
import { LoginMod } from '../shared/PadraoLogin.model';
import {NovoUsuario} from '../shared/NovoUsuario.model'

@Injectable()
export class LoginService {

  user: LoginMod
  userNovo: NovoUsuario

  constructor(private http: HttpClient){}


  login(login: string, senha: string): Observable<LoginMod>{
    console.log("salvar: " + login, senha)
    return this.http.post<LoginMod>(`${URL_API}/login/${login}/${senha}`,{login: login, senha: senha})
                                    .pipe(tap(user => this.user = user))
  }

  isLoggedIn(): boolean{
      //console.log('isLoggedIn-',localStorage.getItem('valido'))
      if  (localStorage.getItem('valido')==='1'){
        return true;
      }else{
        //alert('isLoggedIn-login.serv')
        return false;
      }
    }

  isLoggedInNome(): string{
        return localStorage.getItem('userNome')
    }

  salvarUsuario(login: string, senha: string, senhaConf: string, nomeNv: string, cpfNv: string): Observable<NovoUsuario>{
      console.log("salvarUsuario:loginservice " + login, senha)
      return this.http.post<NovoUsuario>(`${URL_API}/usuarios`,{login: login, senha: senha, nomeNv: nomeNv, cpfNv: cpfNv})
                                      .pipe(tap(user => this.userNovo = user))
    }
}
