import { Component, OnInit, EventEmitter} from '@angular/core';

import {Item} from '../shared/Padroes.model'
import {ItemService} from '../service'
import {LoginService} from '../login/login.service'
import {LoginMod} from '../shared/PadraoLogin.model'
import {MensagemService} from '../mensagem/mensagem.service'

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css'],
  providers: [ItemService, LoginService, MensagemService]
})
export class CabecalhoComponent implements OnInit {
  /*public itens: Item[] = [
    {It: 'Item-111', Sit: false, Sub: [
			]},
    {It: 'Item-222 com DropSown', Sit: false, Sub: [
				{"url": "/assets/ofertas/1/img123.jpg", SubSub: [
    				{"urlurl": "/assets/ofertas/1/img456.jpg"},
    				{"urlurl": "/assets/ofertas/1/img789.jpg"}],
        },
				{"url": "/assets/ofertas/1/img2.jpg"},
				{"url": "/assets/ofertas/1/img3.jpg"},
				{"url": "/assets/ofertas/1/img4.jpg"}
			]},
    {It: 'Item-333', Sit: true, Sub: [
				{"url": "/assets/ofertas/1/img1.jpg"},
				{"url": "/assets/ofertas/1/img2.jpg"},
				{"url": "/assets/ofertas/1/img3.jpg"},
				{"url": "/assets/ofertas/1/img4.jpg"}
			]},
    {It: 'Item-444', Sit: false, Sub: [
				{"url": "/assets/ofertas/1/img1.jpg"},
				{"url": "/assets/ofertas/1/img2.jpg"},
				{"url": "/assets/ofertas/1/img3.jpg"},
				{"url": "/assets/ofertas/1/img4.jpg"}
			]},
    {It: 'Item-555', Sit: false, Sub: []}


  ]*/
  //public disab: string = 't'
  public itens: Item[]
  public IsLog: boolean = false
  public IsLogAnt: boolean = false
  public JaCarregouCab: boolean = false
  public Usuario: string = 'Default'
  public user: LoginMod
  public data: number = Date.now();
  public espaco: string = ''

  constructor(private itemService: ItemService,
              private loginService: LoginService,
              private mensagemService: MensagemService) { }

  ngOnInit() {
  //  this.itens=this.itemService.itens
    //console.log(this.itens)
    //this.Usuario= this.LoginComponent.userNome
    this.CarregaCabecalho()



}
  public setarUsuario(usu: LoginMod){
    alert('setarUsuario'+usu.userNome)
    //console.log('this.Usuario',usu)
    this.Usuario=usu.userNome
  }

  isLoggedIn(CargaCab: boolean): boolean{
      //alert(this.loginService.user)
      this.IsLog=this.loginService.isLoggedIn()
      if (this.IsLog && !this.IsLogAnt){
        this.IsLogAnt=this.IsLog
        this.CarregaCabecalho()
      }
      return this.IsLog
    }

  CarregaCabecalho(){
    this.itemService.getCabecalhoPorId(localStorage.getItem('userId'))
      .then((itens: Item[]) => {
        this.itens = itens
        this.JaCarregouCab=true
      })
      .catch(
          (param: any) => {console.log(param)}
      )
  }

  isLoggedInNome(): string{
    //console.log('isLoggedInNome', this.loginService.isLoggedInNome())
    return this.loginService.isLoggedInNome()
  }

  logout(){
    //console.log('logout')
    localStorage.clear()
    this.IsLogAnt=false
    this.CarregaCabecalho()
    this.mensagemService.Mensagens('Logout executado com sucesso!!!', 1)
  }

  esconderCollapse(): void{
    let element = document.getElementById("Esquecido") as any;
    let element1 = document.getElementById("demo01") as any;

    if (element1.getAttribute( 'class' )=="collapse show"){
       element1.setAttribute("class", "collapse");
     }
    if (element.getAttribute( 'class' )=="collapse show") {
      element.setAttribute("class", "collapse");
    }
  }
}
