import { Component, NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { FormControl } from '@angular/forms';
type SN = string | number;

interface Pattern {
  [character: string]: {
    pattern: RegExp;
  };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'RH Carreira';
  public customMaska: [string, Pattern];
  public formDate: FormControl;
  public cpfFormControl: FormControl;
  public clearIfNotMatch: FormControl;
}
