import { Component, OnInit } from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations'

import {MensagemService} from './mensagem.service'

import {Observable, timer} from 'rxjs'

import {tap, switchMap} from 'rxjs/operators'

@Component({
  selector: 'app-mensagem',
  templateUrl: './mensagem.component.html',
  styleUrls: ['./mensagem.component.css'],
  providers: [MensagemService],
  animations: [
    trigger('snack-visibility', [
      state('hidden', style({
        opacity: 0,
        bottom: '0px'
      })),
      state('visible', style({
        opacity: 1,
        bottom: '30px'
      })),
      transition('hidden => visible', animate('500ms 0s ease-in')),
      transition('visible => hidden', animate('500ms 0s ease-out'))

    ])
  ]
})
export class MensagemComponent implements OnInit {

  message: string = 'Hello there!'

  snackVisibility: string = 'hidden'

  TipoMensagem: string=''

  constructor(private mensagemService: MensagemService) { }


  ngOnInit() {
    /*console.log(this.snackVisibility)
    this.mensagemService.notifier
    .pipe(
      tap(message=> {
        this.message = message
        this.snackVisibility= 'hidden'
      }),
      switchMap(message => timer(5000))
    ).subscribe(timer => this.snackVisibility = 'hidden')
    console.log(this.snackVisibility)*/

  }

  mostrarMensagem(){
    //console.log('mostrarMensagem1-'+mens)

    let bu=document.getElementById("txtTipo") as any;
    let element = document.getElementById("txtMensagem") as any;
    let element1 = document.getElementById("txtMensagemta") as any;
    let ele = document.getElementById("snackVisibilityId") as any;
    if (bu.value==='1'){
      this.message = element.value
      ele.style='background-color: black; min-width: 250px; margin-left: -125px; left: 50%; z-index:99;'
    }else{
      this.message = element1.value
      ele.style='background-color: red; min-width: 250px; margin-left: 350px; left: 0%; max-width: 750px; z-index:99;'
    }
      this.snackVisibility = this.snackVisibility === 'hidden' ? 'visible' : 'hidden'

      //console.log('mostrarMensagem2-'+this.snackVisibility)
      //setTimeout(this.apagarMensagem(), 50000);
      /*let start:any = new Date().getTime();
      let res: any
      let ant:any = 0
      console.log(start)
      for (var i=0; i<=10000; i++) {
        let time:any =  new Date().getTime();
        res=(time - start) / 1000;
//console.log(res)
        if (res>4) break;
        if (res>ant){
          res=ant
          console.log(res)
        }
      }*/
       //console.log('mostrarMensagem2.1-'+this.snackVisibility)
      //this.snackVisibility = this.snackVisibility === 'hidden' ? 'visible' : 'hidden'
      //console.log('mostrarMensagem-3'+this.snackVisibility)

  }
  apagarMensagem(){

    //console.log('apagarMensagem-00'+this.snackVisibility)

    //this.snackVisibility = this.snackVisibility === 'hidden' ? 'visible' : 'hidden'

  }
}
