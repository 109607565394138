export class Selecoes{

  constructor(sid: string, Descricao: string, DataCadastro: string, Questionario: string, QuestionarioId: string, Empresa: string, Situacao: boolean, TempoProva: string, QtdPessoas: number, Questionarios: Array<object>, Tipo: string, DataMaxima: string, Pessoas: Array<object>)
   {
      this.sid = sid;
      this.Descricao = Descricao;
      this.DataCadastro = DataCadastro;
      this.Questionario = Questionario;
      this.QuestionarioId = QuestionarioId;
      this.Empresa = Empresa;
      this.Situacao = Situacao;
      this.TempoProva = TempoProva;
      this.QtdPessoas = QtdPessoas;
      this.Questionarios= Questionarios;
      this.Tipo=Tipo;
      this.DataMaxima=DataMaxima;
      this.Pessoas=Pessoas;
    }

  public sid: string
  public Descricao: string
  public DataCadastro: string
  public Questionario: string
  public QuestionarioId: string
  public Empresa: string
  public Situacao: boolean
  public TempoProva: string
  public QtdPessoas: number
  public Questionarios: Array<object>
  public Tipo: string
  public DataMaxima: string
  public Pessoas: Array<object>
}
