import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs'

import {ItemService} from '../service'
import {Cidade} from '../shared/PadraoCidade.model'

@Component({
  selector: 'app-tela21',
  templateUrl: './tela21.component.html',
  styleUrls: ['./tela21.component.css'],
  providers: [ItemService]
})
export class Tela21Component implements OnInit {


  public citys: Observable<Cidade[]>
  public citys2: Cidade[]

  // pager object
     pager: any = {};

     // paged items
     pagedItems: any[];


  constructor(private itemService: ItemService) { }

  ngOnInit() {
    let val: string=null
    this.itemService.pesquisaCidades(val)
    .subscribe((cit: Cidade[]) => {this.citys2 = cit
                                  this.setPage(1)},
              error => console.log('erro no acesso as cidades',error)
            )


  }


  setPage(page: number) {
          // get pager object from service
          this.pager = this.getPager(this.citys2.length, page);

          // get current page of items
          this.pagedItems = this.citys2.slice(this.pager.startIndex, this.pager.endIndex + 1);
      }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 15) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

}
