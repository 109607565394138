import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {LoginService} from '../login/login.service'

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private loginservice: LoginService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.loginservice.isLoggedIn()) {
            // logged in so return true

            return true;
        }
        console.log('canActivate false')
        // not logged in so redirect to login page with the return url and return false
        this.router.navigate([''], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
