import { AbstractControl } from '@angular/forms';

export class Validacoes {
  static ValidaCpf(controle: AbstractControl) {
    const Cpf = controle.value;

    let soma: number = 0;
    let resto: number;
    let valido: boolean;

    const regex = new RegExp('[0-9]{11}');

    if (
      Cpf == '00000000000' ||
      Cpf == '11111111111' ||
      Cpf == '22222222222' ||
      Cpf == '33333333333' ||
      Cpf == '44444444444' ||
      Cpf == '55555555555' ||
      Cpf == '66666666666' ||
      Cpf == '77777777777' ||
      Cpf == '88888888888' ||
      Cpf == '99999999999' ||
      !regex.test(Cpf)
    )
      valido = false;
    else {
      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(Cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(Cpf.substring(9, 10))) valido = false;

      soma = 0;
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(Cpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(Cpf.substring(10, 11))) valido = false;
      valido = true;
    }

    if (valido) return null;

    return { CpfInvalido: true };
  }

  static MaiorQue18Anos(controle: AbstractControl) {
    const Nascimento = controle.value;
    const arrN=Nascimento.split('');
    const dia=arrN[0]+arrN[1];
    const mes=arrN[2]+arrN[3];
    const ano=arrN[4]+arrN[5]+arrN[6]+arrN[7];;
    //const [ano, mes, dia] = Nascimento.split(3,4);
    const hoje = new Date();
    const dataNascimento = new Date(ano, mes, dia, 0, 0, 0);
    const tempoParaTeste = 1000 * 60 * 60 * 24 * 365 * 18; //18 anos em mili segundos...
    
    if (hoje.getTime() - dataNascimento.getTime() >= tempoParaTeste)
      return null;

    return { menorDeIdade: true };
  }

  static SenhasCombinam(controle: AbstractControl) {
    let senha = controle.get('senha').value;
    let confirmarSenha = controle.get('confirmarSenha').value;

    if (senha === confirmarSenha) return null;

    controle.get('confirmarSenha').setErrors({ senhasNaoCoincidem: true });
  }
}